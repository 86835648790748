<template>
  <div>
    <component-transaction-report v-if="transaction.render" :license="transaction.license"
      v-on:back="transaction.render = false"></component-transaction-report>
    <div class="bg-white shadow-sm rounded-lg p-4" v-else>
      <div class="row">
        <div v-if="this.$store.getters.discriminator === 'administrator'" class="col-2 ml-0 p-0">
          <multiselect v-model="oficeFilter" @input="filterData" :options="offices" :multiple="true"
            :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Offices"
            label="office_name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity"
            @close="closeBackgroundOpacity">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{
                  values
                    .map((item) => {
                      return item.office_name;
                    })
                    .join(", ")
                }}</span></template>
          </multiselect>
        </div>
        <div v-if="clients.length > 0" class="col-2 ml-2 p-0">
          <multiselect v-model="clientFilter" @input="filterData" :options="clients" :multiple="true"
            :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Client"
            label="name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity"
            @close="closeBackgroundOpacity">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{
                  values
                    .map((item) => {
                      return item.name;
                    })
                    .join(", ")
                }}</span></template>
          </multiselect>
        </div>
        <div class="col-3 ml-2 p-0">
          <multiselect v-model="licenseFilter" @input="filterData" :options="licenseTypes" :multiple="true"
            :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select License"
            label="name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity"
            @close="closeBackgroundOpacity">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{
                  values
                    .map((item) => {
                      return item.name;
                    })
                    .join(", ")
                }}</span></template>
          </multiselect>
        </div>
        <div class="col-1 ml-2 p-0">
          <el-select v-model="statusFilter" @change="filterData" placeholder="Status" class="w-100">
            <el-option value="-1" label="All">All</el-option>
            <el-option value="1" label="Current">Current</el-option>
            <el-option value="0" label="Expired">Expired</el-option>
          </el-select>
        </div>
        <div class="col-3 ml-2 p-0">
          <date-picker v-model="dateFilter" type="date" @change="filterData" range format="MM/DD/YYYY"
            placeholder="Select Range Date"></date-picker>
        </div>
      </div>
      <el-table ref="table" :default-sort="{ prop: 'corporate.name', order: 'ascending' }" id="exportLicenses" :data="tableData.filter(
        (data) =>
          !search ||
          data.corporate.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          data.corporate.office.office_name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          data.license_type.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          data.issueDate.toLowerCase().includes(search.toLowerCase())
      )
        " style="width: 100%">
        <el-table-column sortable prop="corporate.name" label="Client"></el-table-column>
        <el-table-column sortable prop="corporate.office.office_name" label="Office"></el-table-column>
        <el-table-column sortable prop="license_type.name" label="License Type"></el-table-column>
        <el-table-column sortable prop="issueDate" label="Issue Date" width="150">
          <template slot-scope="scope">{{
            scope.row.issueDate | moment("MM-DD-YYYY")
          }}</template>
        </el-table-column>
        <el-table-column sortable prop="dueDate" label="Due Date" width="150">
          <template slot-scope="scope">{{
            scope.row.dueDate | moment("MM-DD-YYYY")
          }}</template>
        </el-table-column>
        <el-table-column sortable prop="status" label="L. Status" width="100">
          <template slot-scope="scope">{{
            scope.row.status ? "Current" : "Expired"
          }}</template>
        </el-table-column>
        <el-table-column sortable prop="corporate.status" label="C. Status" width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="updateState(scope.row)">
              {{
                scope.row.client_status ? "Active" : "Inactive"
              }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Operations" width="230"
          v-if="this.$store.getters.discriminator === 'administrator' && exportDataExcel === false">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" placeholder="Search..." class="p-0" :key="scope.row" />
          </template>
          <template slot-scope="scope">
            <center>
              <el-button-group>
                <el-button size="mini" icon="el-icon-money" class="mr-1"
                  @click="openTransaction(scope.row, scope.$index)">Transactions</el-button>
                <el-button size="mini" icon="el-icon-edit" @click="update(scope.row, scope.$index)"></el-button>
                <el-button size="mini" icon="el-icon-delete" @click="remove(scope.row, scope.$index)"></el-button>
              </el-button-group>
            </center>
          </template>
        </el-table-column>
      </el-table>

      <el-button class="fixed-bottom new-register" type="success" @click="add">Add New License</el-button>

      <download-excel class="el-button fixed-bottom new-register el-button--primary fixed-bottom new-register"
        style="margin-left:140px;" :fetch="exportExcel" worksheet="Corporates" name="licenses.xls">
        Export Excel
      </download-excel>
      <!-- <el-button class="fixed-bottom new-register" style="margin-left:140px;" @click="
        () => {
          exportDataExcel = true;
          exportExcel();
        }
      ">Export CSV</el-button> -->

      <el-drawer title="Licenses" :visible.sync="component.drawer" direction="rtl" size="1000px">
        <div class="pt-3 pl-5 pr-5 pb-5">
          <component-license v-on:refresh="load($event)" :data="component.data" :key="component.render" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import license from "@/services/api/license";
import licenseType from "@/services/api/licenseType";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import corporate from "@/services/api/corporate";
import ComponentLicense from "@/components/admin/License";
import ComponentTransactionReport from "@/components/admin/license/TransactionReport";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { ToggleButton } from 'vue-js-toggle-button';
import "vue2-datepicker/index.css";
import FileSaver from "file-saver";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    ComponentLicense,
    ComponentTransactionReport,
    Multiselect,
    DatePicker,
    downloadExcel
  },
  data() {
    return {
      search: "",
      data: [],
      exportDataExcel: false,
      tableData: [],
      discriminator: "",
      oficeFilter: [],
      clientFilter: [],
      licenseFilter: [],
      statusFilter: null,
      dateFilter: [null, null],
      offices: [],
      clients: [],
      licenseTypes: [],
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      transaction: {
        license: null,
        render: false,
      },
      index: null,
    };
  },
  mounted() {
    licenseType.get().then((response) => {
      this.licenseTypes = response;
    });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        license.get().then((response) => {
          this.data = Object.assign([], response);
          this.tableData = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
          license.get().then((response) => {
            let filter = [...response].filter(
              (license) => license.corporate.office.id == idOffice
            );
            this.data = Object.assign([], filter);
            this.tableData = filter;
          });
        });
        break;
      }
    }
  },
  methods: {
    exportExcel() {
      return this.tableData.map((item, i) => ({
        "Client Name": item.corporate.name,
        Office: item.corporate.office.office_name,
        "License Type": item.license_type.name,
        "Issue Date":  item.issueDate,
        "Due Date": item.dueDate,
        "L. Status": item.status?"Current" : "Expired",
        "C. Status": item.client_status?"Active" : "Inactive",
      }))
    },
    // exportExcel() {
    //   setTimeout(() => {
    //     /* generate workbook object from table */
    //     var xlsxParam = { raw: true }; // parse only content derived, without performing format conversion
    //     var wb = XLSX.utils.table_to_book(
    //       document.querySelector("#exportLicenses"),
    //       xlsxParam
    //     );

    //     /* get binary string as output */
    //     var wbout = XLSX.write(wb, {
    //       bookType: "csv",
    //       bookSST: true,
    //       type: "array",
    //     });
    //     try {
    //       FileSaver.saveAs(
    //         new Blob([wbout], { type: "application / octet-stream" }),
    //         "Licenses.csv"
    //       );
    //       this.exportDataExcel = false;
    //     } catch (e) {
    //       if (typeof console !== "undefined") {
    //         console.log(e, wbout);
    //       }
    //     }
    //     return wbout;
    //   }, 500);
    // },
    load(event) {
      this.component.drawer = false;
      //
      let idOffices = this.oficeFilter.map((office) => {
        return office.id;
      });
      if (event.update) {
        if (idOffices.length > 0 && idOffices.indexOf(event.data.corporate.officeId) === -1) {
          this.tableData.splice(this.index, 1);
        } else {
          this.tableData.splice(this.index, 1, event.data);
        }
        let indexUpdate = null;
        this.data.forEach(function (element, index, array) {
          if (element.id == event.data.id) {
            indexUpdate = index;
          }
        });

        this.data.splice(indexUpdate, 1, event.data);
      } else {
        if (idOffices.length > 0) {
          if (idOffices.indexOf(event.data.corporate.officeId) != -1) {
            this.tableData.unshift(event.data);
          }
        } else {
          this.tableData.unshift(event.data);
        }
        this.data.unshift(event.data);
      }
    },
    getClients(idOffices) {
      this.clients = [];
      idOffices.forEach((id) => {
        corporate.getClientsByOffice(id).then((response) => {
          this.clients = this.clients.concat(response);
        });
      });
    },
    filterData() {
      let idOffices = this.oficeFilter.map((office) => {
        return office.id;
      });

      let clients = this.clientFilter.map((client) => {
        return client.id;
      });

      let licenses = this.licenseFilter.map((license) => {
        return license.id;
      });

      let status = this.statusFilter;

      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => idOffices.indexOf(item.corporate.officeId) != -1
        );
      }
      if (idOffices.length !== this.lengthOfficeFilter) {
        this.lengthOfficeFilter = idOffices.length;
        this.getClients(idOffices);
      }
      if (idOffices.length == 0) {
        this.lengthOfficeFilter = 0;
        this.clientFilter = [];
        this.clients = [];
      }

      if (clients.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clients.indexOf(item.corporate.id) != -1
        );
      }

      if (licenses.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => licenses.indexOf(item.licenseTypeId) != -1
        );
      }

      if (status != -1 && status != null) {
        this.tableData = this.tableData.filter((item) => status == item.status);
      }

      if (this.dateFilter[0] != null && this.dateFilter[1] != null) {
        this.tableData = this.tableData.filter((item) => {
          var check = new Date(item.dueDate);
          if (check > this.dateFilter[0] && check < this.dateFilter[1]) {
            return true;
          } else {
            return false;
          }
        });
      }
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    updateState(row) {
      let val = '';
      if (row.client_status == 1) {
        val = 0;
      } else {
        val = 1
      }
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change the status of the client!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change!'
      }).then((result) => {
        if (result.isConfirmed) {
          row.client_status = val
          license.updateStateClient(row, val)
            .then((response) => {
              row.client_status = val
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });



    },
    openTransaction(row, index) {
      this.index = index;
      //
      this.transaction.license = row;
      this.transaction.render = true;
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          license.delete(row)
            .then((response) => {
              this.tableData.splice(index, 1);
              this.data.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.mx-datepicker {
  width: 100% !important;
}

.mx-input {
  height: 42px !important;
}
</style>
